import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO/SEO"
import { ProjectProps } from "./Project.models"
import { Container } from "../../components/Container.styles"
import { PageHeading } from "../../components/PageHeading"
import { Stack } from "../../components/Stack"
import { Text } from "../../components/Text/Text"
import { Link } from "../../components/Link"
import { styled } from "../../gatsby-theme-stitches/config"
import { PreviousPageLink } from "../../components/PreviousPageLink"

const ProjectContainer = styled("section", {
  gap: "$xxl",
  gridTemplateColumns: "auto",
  display: "flex",
  flexDirection: "column-reverse",
  "@desktop": {
    display: "grid",
    gridTemplateColumns: "auto 500px",
  },
})

export const ProjectImage = styled("div", {
  backgroundPosition: "center",
  backgroundSize: "cover",
  borderRadius: "$m",
  aspectRatio: "16 / 9",
  "@desktop": {
    aspectRatio: "1 / 1",
  },
})

const Project: FunctionComponent<ProjectProps> = ({
  data: { sanityProject: project },
}): JSX.Element => (
  <Layout>
    <SEO title={`${project.name}`} />
    <Container>
      <PreviousPageLink to="/">
        <em>Back to Projects</em>
      </PreviousPageLink>
      <ProjectContainer>
        <Stack space="l">
          <PageHeading title={project.name}>
            <em>{project.tools.join(", ")}</em>
          </PageHeading>
          <Text as="span" variant="body-1" css={{ lineHeight: 2.0 }}>
            <BlockContent blocks={project._rawBody} />
          </Text>
          {project.url && (
            <Link to={project.url}>
              <Text as="span" variant="body-1">
                {project.urlText}
              </Text>
            </Link>
          )}
        </Stack>
        <ProjectImage
          css={{
            backgroundImage: `url('${project.mainImage?.asset?.url}')`,
          }}
        />
      </ProjectContainer>
    </Container>
  </Layout>
)

export const query = graphql`
  query Project($id: String) {
    sanityProject(id: { eq: $id }) {
      url
      tools
      slug {
        current
      }
      mainImage {
        asset {
          url
        }
      }
      name
      _rawBody
      urlText
    }
  }
`

export default Project
